/* eslint-disable react-hooks/exhaustive-deps */
import Input, {InputProps} from "@mui/joy/Input";
import useTranslation from "../../hooks/contexts/TranslationContext";
import FormLabel from "@mui/joy/FormLabel";
import moment from "moment";
import {Grid} from "@mui/joy";
import useOrganisation from "../../hooks/contexts/OrganisationContext";
import FormControl from "@mui/joy/FormControl";
import React, {useEffect, useState} from "react";
import {toInputDateFormat} from "../../utils/date";

export default function InputDateTimeControl({label, error, inputProps, value, setValue}: {
    label?: string | undefined,
    error?: undefined | boolean,
    inputProps?: InputProps,
    value: Date,
    setValue: React.Dispatch<React.SetStateAction<Date>>
}) {
    const {organisation} = useOrganisation()
    const {t} = useTranslation()
    const [date, setDate] = useState(moment().toDate())
    const [time, setTime] = useState(moment(value).format('HH:mm'))

    useEffect(() => {
        setValue(moment(`${toInputDateFormat(date)}T${time}:00`)
            .utcOffset(organisation.getSafeTimezoneId).toDate())
    }, [date, organisation.getSafeTimezoneId, time])

    return <>
        {label && <FormControl sx={{my: 0.5}}><FormLabel>{t(label)}</FormLabel></FormControl>}
        <Grid container spacing={1}>
            <Grid md={12} lg={7}>
                <Input defaultValue={toInputDateFormat(date)} type={"date"} {...inputProps} error={error}
                       onChange={e => {
                           if (moment(e.target.value).isValid() && e.target.value !== "") {
                               setDate(moment(e.target.value).toDate())
                           }
                       }}
                />
            </Grid>
            <Grid md={12} lg={5}>
                <Input sx={{mr: 1}} defaultValue={time} type={"time"} {...inputProps} placeholder={"00:00"}
                       onChange={e => {
                           setTime(e.target.value)
                       }}
                />
            </Grid>
        </Grid>
    </>
}
