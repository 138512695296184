import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import ClubEvent, {ClubEventPhase, EventModifyPhase} from "../../types/ClubEvent";
import useGetSingle from "../useHttp";
import LoadingPage from "../../pages/LoadingPage";
import {useParams} from "react-router-dom";
import Error404 from "../../pages/errors/Error404";
import useNotifications from "./NotificationContext";
import useTranslation from "./TranslationContext";
import {putObject} from "../../functions/objectService";
import useOrganisation from "./OrganisationContext";
import moment from "moment";

const ClubEventContext = createContext<{
    clubEvent: null | ClubEvent
    updateEvent: (e: ClubEvent) => void
    saveChanges: () => void,
    modifyPhase: EventModifyPhase,
    setModifyPhase: (e: EventModifyPhase) => void
}>({
    clubEvent: null,
    updateEvent: () => {
    },
    saveChanges: () => {
    },
    modifyPhase: EventModifyPhase.DisplayInfo,
    setModifyPhase: () => {
    },
})

export default function useClubEvent() {
    return useContext(ClubEventContext)
}

export function ClubEventProvider(props: { children: ReactNode }) {
    const {ORG_URI, orgNavigate} = useOrganisation()
    const {clubEventId} = useParams()
    const {
        data,
        firstLoading,
    } = useGetSingle<ClubEvent | null>(`/organisations/events/get/${clubEventId}`, null)
    const [clubEvent, setClubEvent] = useState(data)
    const [modifyPhase, setModifyPhase] = useState(EventModifyPhase.DisplayInfo)
    const {success, error} = useNotifications()
    const {t} = useTranslation()

    let errors: string[] = []

    useEffect(() => {
        if (!firstLoading) {
            setClubEvent(data)
            if (data?.status === ClubEventPhase.SetupCompleted)
                setModifyPhase(EventModifyPhase.ManageRegistrations)
        }
    }, [firstLoading, data])

    if (clubEventId === undefined) return <Error404/>

    if (firstLoading) return <LoadingPage/>

    if (clubEvent === null) return <Error404/>

    async function saveChanges() {
        try {
            if (anyErrors()) {
                errors.forEach(e => error(e))
                return;
            }
            await putObject(`${ORG_URI}/Events/Update`, {entity: clubEvent})
            success(t("Successfully saved event!"))
            if (clubEvent?.status === ClubEventPhase.Cancelled)
                orgNavigate("")
        } catch (e) {
            error(`${e}`)
        }
    }

    function anyErrors(): boolean {
        errors = []
        if (clubEvent === null) {
            errors.push(t("Event not defined, INTERNAL SERVER ERROR 500"))
            return true
        }
        if (clubEvent.title.length === 0)
            errors.push(t("Title of event is a mandatory field"))

        if (!moment(clubEvent.endRegistrations).isSameOrAfter(clubEvent.startRegistrations))
            errors.push(t("End date of registrations must be after the start date"))

        if (!moment(clubEvent.endEvent).isSameOrAfter(clubEvent.startEvent)) {
            console.log(clubEvent.endEvent)
            console.log(clubEvent.startEvent)
            errors.push(t("End date of event must be after the start date"))
        }

        if (clubEvent.status === ClubEventPhase.Cancelled)
            return false

        return errors.length > 0
    }

    function updateModel(e: ClubEvent) {
        setClubEvent(e)
    }

    return <ClubEventContext.Provider value={{
        clubEvent,
        saveChanges,
        updateEvent: updateModel,
        modifyPhase,
        setModifyPhase
    }}>
        {props.children}
    </ClubEventContext.Provider>
}
