import TabSettingLayout from "./TabSettingLayout";
import InputControl from "../../form/InputControl";
import {useForm} from "react-hook-form";
import useGetSingle from "../../../hooks/useHttp";
import useOrganisation from "../../../hooks/contexts/OrganisationContext";
import LoadingPage from "../../../pages/LoadingPage";
import useNotifications from "../../../hooks/contexts/NotificationContext";
import {putObject} from "../../../functions/objectService";
import {CenterDiv} from "../../layout/FlexDiv";
import Button from "@mui/joy/Button";
import {SaveIcon} from "../../layout/CrudIcons";
import {useState} from "react";
import IconButton from "@mui/joy/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import useTranslation from "../../../hooks/contexts/TranslationContext";
import Box from "@mui/joy/Box";
import useScreen from "../../../hooks/useScreen";
import Input from "@mui/joy/Input";
import axios from "axios";

export default function PaymentInfoSettings() {
    const {ORG_URI} = useOrganisation()
    const {data, firstLoading} = useGetSingle<{
        publicKey: string,
        secretKey: string,
        id: string,
    } | null>(`${ORG_URI}/Settings/Payment`, null)
    const {register, getValues} = useForm()
    const {success, error} = useNotifications()
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation()
    const {PhoneOrTablet} = useScreen()


    if (firstLoading)
        return <LoadingPage/>

    async function onSubmit() {
        try {
            await putObject(`${ORG_URI}/Settings/Payment`, {
                ...data,
                publicKey: getValues("publicKey"),
                secretKey: getValues("secretKey")
            })
            success("Successfully saved credentials")
        } catch (e: any) {
            error(e)
        }
    }

    return <>
        {!PhoneOrTablet && <Box sx={{mb: 1}}>
            <small>
                <strong>{t("How do payments work?") + " "}</strong>
                {t("The user will be redirected to a payment page provided by Stripe. Once the user is redirected back to our website, we will immediately check if the payment succeeded.")}
                {t("If the payment did not succeed immediately, we will check the status of this invoice every 2 minutes.")}
                {t("The user has the responsibility to know whether he has paid. Until we are 100% sure that the payment has been received, we will offer a new chance to pay.")}
                {t("Stripe offers a refund method on their website, so if double payments have been received you can easily refund the person on their dashboard.")}
            </small>
        </Box>}
        <TabSettingLayout question={"Webhook"}>
            <Input sx={{m: 1}} readOnly={true}
                   value={`${axios.defaults.baseURL}/Organisations/Payments/Stripe/Webhook`}/>
        </TabSettingLayout>
        <TabSettingLayout question={"Public key"}>
            <InputControl register={register("publicKey", {value: data?.publicKey})}/>
        </TabSettingLayout>
        <TabSettingLayout question={"Secret key"}>
            <InputControl
                inputProps={{
                    type: showPassword ? "text" : "password", endDecorator: <IconButton
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                }}
                register={register("secretKey", {value: data?.secretKey})}/>
        </TabSettingLayout>
        <CenterDiv>
            <Button color={"success"} onClick={onSubmit}><SaveIcon/> Save settings</Button>
        </CenterDiv>
    </>
}